<template>
  <v-app>
    <v-dialog
        v-model="noEnvTourDialog"
        width="80%"
    >
      <v-card>
        <v-card-title class="text-h3 white--text pb-3 primary">
          Welcome to <strong class="black--text">M360API</strong> Console
        </v-card-title>

        <v-card-text class="text-center">
          <p>In this quick tour, we'll show you how to get started and where to find things.</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
              text
              depressed
              @click="closeNoEnvTour"
          >
            Don't show this tour again
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
              color="primary"
              @click="startNoEnvTour"
          >
            Start Tour
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
    <dashboard-core-app-bar :env-selected="envSelected" :environments="environments" v-model="expandOnHover" @refreshEnv="refreshEnvironments" @switchEnv="switchEnvironment"/>

    <dashboard-core-drawer :env-selected="envSelected" :environments="environments" :expand-on-hover.sync="expandOnHover"/>

    <dashboard-core-view :env-selected="envSelected" :environments="environments" @switchEnv="switchEnvironment"/>

    <dashboard-core-settings v-model="expandOnHover" :env-selected="envSelected" :environments="environments"/>

    <template v-if="tourSteps && tourSteps.length > 0">
      <v-tour name="consoleNoenvTour" :steps="tourSteps" :options="{ highlight: true }" :callbacks="tourCallbacks"></v-tour>

      <v-tour name="consoleYesenvTour" :steps="tourSteps" :options="{ highlight: true }" :callbacks="tourCallbacks"></v-tour>
    </template>
  </v-app>
</template>

<script>
import globalMixins from "../../mixins/globalMixins";

export default {
  name: 'DashboardIndex',

  mixins: [ globalMixins ],

  components: {
    DashboardCoreAppBar: () => import('./components/template/AppBar'),
    DashboardCoreDrawer: () => import('./components/template/Drawer'),
    DashboardCoreSettings: () => import('./components/template/Settings'),
    DashboardCoreView: () => import('./components/template/View'),
  },

  data: () => ( {
    noEnvTourDialog: false,
    expandOnHover: false,
    envSelected: {
      name: 'Portal',
      value: 'portal'
    },
    environments: [],

    tourSteps: [],

    tourCallbacks: {}
  } ),

  async created() {
    this.refreshEnvironments();
  },

  updated() {
    const _self = this;
    const user = this.$store.getters[ 'auth/getUser' ];
    const acl = this.$store.getters[ 'auth/getACL' ];
    if ( !user || !acl ) {
      this.getSendData( {
                          'url': '/user',
                          'method': 'get'
                        } ).then( response => {
        this.$store.dispatch( 'auth/setUserAndACL', response );
      } ).catch( error => {
        handleErrorResponse( error );
      } );
    }

    function handleErrorResponse( error ) {
      _self.$store.dispatch( 'auth/clear' );
      if ( _self.$router.currentRoute.name !== 'Login' ) {
        _self.$router.push( { name: 'Login' } );

        setTimeout( () => {
          for ( let code in error ) {
            let oneError = error[ code ];
            _self.pushMessage( {
                                 'type': 'error',
                                 'title': `Error: ${ oneError.code }`,
                                 'text': oneError.message.toString()
                               } );
          }
        }, 500 );
      }
    }
  },

  methods: {

    closeNoEnvTour: function () {
      this.noEnvTourDialog = false;
      this.$cookies.set('close_console_noenv_tour', true, "1y");
    },

    startYesEnvTour: function () {
      this.tourCallbacks = {
        onFinish: this.setYesEnvTourCookie,
        onSkip: this.setYesEnvTourCookie,
        onStop: this.setYesEnvTourCookie
      };

      this.tourSteps = [
        {
          target: '#switch-env-menu',
          content: 'The Console offers one UI to manage all the environment you create. You can simply switch between these environment using this menu.',
          params: {
            enableScrolling: false
          }
        },
        {
          target: '#environment-menu',
          content: `The Environment Menu contains the module that enable you to modify the settings of an environment, link resources, create tenant applications and visualize what is running in this environment.`,
          params: {
            placement: 'right',
            enableScrolling: false
          }
        },
        {
          target: '#settings-module',
          content: `This Module allows you to modify the registry settings of the environment, create you own custom settings, and configure Automated Registration Workflows. All these settings are then pulled by the Gateway running in this environment when you deploy it. These settings also become available to use in your custom microservice via the Gateway's Maintenance port and by using the Middleware.`,
          params: {
            placement: 'right',
            enableScrolling: false
          }
        },
        {
          target: '#resources-module',
          content: `This Module allows you to link the resources that your microservices will operate on, and create and manage the database in these resources. The configuration you provide here will also be available to use in your custom microservice via the Gateway's Maintenance port and by using the Middleware.`,
          params: {
            placement: 'right',
            enableScrolling: false
          }
        },
        {
          target: '#applications-module',
          content: `This Module allows you to create and manage multitenant applications. These applications can provide your code with custom configuration where this configuration is not exposed to the public. The configuration you provide here will also be available to use in your custom microservice via the Gateway's Maintenance port and by using the Middleware.`,
          params: {
            placement: 'right',
            enableScrolling: false
          }
        },
        {
          target: '#catalog-module',
          content: `This Module helps you visualize what is running in this environment. Using the Gateway's cloud awareness feature, it prints out all the microservices that are deployed behind the gateway and that are using the middleware. The catalog shows all the versions and instances of each deployed microservice and supports triggering the maintenance operations of each microservice.`,
          params: {
            placement: 'right',
            enableScrolling: false
          }
        },
        {
          target: '#dashboard-card',
          content: `Each Environment is represented by a card in the dashboard page of the Console. You can click the icons inside each card to directly navigate to the module it represents AND in that environment.`,
          params: {
            placement: 'top',
            highlight: false,
            enableScrolling: false
          }
        },
      ];
      this.$forceUpdate();
      this.$nextTick(() => {
        this.$tours['consoleYesenvTour'].start()
      });
    },

    startNoEnvTour: function () {
      this.tourCallbacks = {
        onFinish: this.setNoEnvTourCookie,
        onSkip: this.setNoEnvTourCookie,
        onStop: this.setNoEnvTourCookie
      };
      this.tourSteps = [
        {
          target: '#welcome-blank-env',
          content: `The Console enables you to create virtual environments so you can deploy the M360API gateway in them. Once created, you can configure the different settings of each environment via the modules that will appear in the left sidebar. After you deploy the M360API Gateway, head to the API catalog module in the sidebar to visualize and operate it.`,
          params: {
            placement: 'top',
            enableScrolling: false
          }
        },
        {
          target: '#global-menu',
          content: 'The Global Menu is located on the top right corner of this screen. The modules inside this menu are global and that means they are always available and accessible regardless if you have environments or not. In addition, the data inside these module does not depend on the environment that you are working in.',
          params: {
            enableScrolling: false
          }
        },
        {
          target: '#section-menu',
          content: `The Section Menu provides quick access to juggle between the Global and the Environment Menus.`,
          params: {
            enableScrolling: false
          }
        },
        {
          target: '#environment-menu',
          content: `The Environment Menu contains the module that enable you to modify the settings of an environment, link resources, create tenant applications and visualize what is running in this environment. This menu is located in the sidebar, if you don't see any module at the moment, that's because you don't have any environments created.`,
          params: {
            placement: 'right',
            enableScrolling: false
          }
        },
        {
          target: '#new-env-btn',
          content: 'Therefore, the first Step is to create an environment, and you can do that by clicking this button and following the create environment wizard.',
          params: {
            enableScrolling: false
          }
        },

      ];
      this.noEnvTourDialog = false;
      this.$forceUpdate();
      this.$nextTick(() => {
        this.$tours['consoleNoenvTour'].start()
      });
    },

    setNoEnvTourCookie(){
      this.$cookies.set('close_console_noenv_tour', true, "1y");
    },

    setYesEnvTourCookie(){
      this.$cookies.set('close_console_yesenv_tour', true, "1y");
    },

    refreshEnvironments: async function () {
      const user = this.$store.getters[ 'auth/getUser' ];
      const acl = this.$store.getters[ 'auth/getACL' ];
      if ( user && acl ) {
        let environments;
        if ( !this.environments || this.environments.length === 0 ) {
          environments = await this.listEnvironments();
        }
        else {
          environments = this.environments;
        }

        if ( environments && environments.length > 0 ) {
          let env = this.$store.getters[ 'env/get' ];
          if ( env && env.value ) {
            this.envSelected = env;
          }
          else {
            this.envSelected = {
              name: environments[ 0 ].name,
              value: environments[ 0 ].code.toLowerCase(),
              color: environments[ 0 ].color
            };
            this.$store.dispatch( 'env/set', this.envSelected );
          }

          const environment = await this.getSendData( {
                                                        url: `/consoleapi/environments/${ this.envSelected.value }`,
                                                        method: "get",
                                                      } );
          this.$emit( 'switchEnv', environment.item.registry );
          this.$store.dispatch( 'auth/setACL', {
            env: environment.item.registry.code.toLowerCase(),
            acl: environment.item.acl
          } );
          this.$forceUpdate();
          if ( this.$children && this.$children[ 0 ] && this.$children[ 0 ].$children ) {

            if ( this.$children[ 0 ].$children[ 0 ] && Object.hasOwnProperty.call( this.$children[ 0 ].$children[ 0 ], 'renderMenu' ) ) {
              this.$children[ 0 ].$children[ 0 ].renderMenu();
            }

            if ( this.$children[ 0 ].$children[ 1 ] && Object.hasOwnProperty.call( this.$children[ 0 ].$children[ 1 ], 'renderMenu' ) ) {
              this.$children[ 0 ].$children[ 1 ].renderMenu();
            }
          }

          if(!this.$cookies.get( 'close_console_yesenv_tour' )){
            this.startYesEnvTour();
          }
        }
        else {
          if ( !this.$cookies.get( 'close_console_noenv_tour' ) ) {
            this.noEnvTourDialog = true;
          }

        }

      }
    },

    switchEnvironment: function ( item, $event ) {
      item = this.addMetaData( item );
      //set the env
      this.envSelected = item;

      //update local storage
      this.$store.dispatch( 'env/set', item );
    },

    listEnvironments: async function () {

      let environments = await this.getSendData( {
                                                   'url': '/consoleapi/environments',
                                                   'method': 'get'
                                                 } );

      if ( environments ) {
        if ( !environments.items && environments.data && environments.data.items ) {
          environments = environments.data;
        }
        this.environments = environments.items.map( this.addMetaData );
        return this.environments;
      }
    },

    addMetaData: function ( env ) {
      const codeLowerCase = env.code.toLowerCase();
      return {
        ...env,
        name: env.code,
        code: codeLowerCase,
        value: codeLowerCase,
        color: env.color
      };
    },
  }
}
</script>

<style>

.no-bgBtn.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: #fff
}

.no-bgBtn.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined):hover {
  background-color: #eee
}

.v-tour .v-step {
  background-color: #FFA72D;
  color: black;
}

.v-tour__target--highlighted {
  -webkit-box-shadow: 0 0 0 4px #FFA72D;
  box-shadow: 0 0 0 4px #FFA72D;
}

</style>